import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TelenetIconLibrary } from './telenet-icon-library.service';

@Component({
   selector: 'telenet-icon',
   template: `<ng-content></ng-content>`,
   styles: [
      ':host {display: inline-block;}',
      ':host::ng-deep svg{width: 100%; height: 100%;}'
   ],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelenetIconComponent {
   private svgIcon: SVGElement;

   @Input()
   set name(iconName: string) {
      if (this.svgIcon) {
         this.element.nativeElement.removeChild(this.svgIcon);
      }

      const svgData = this.telenetIconsRegistry.getIcon(iconName);

      this.svgIcon = this.svgElementFromString(svgData);
      this.element.nativeElement.appendChild(this.svgIcon);
   }

   constructor(private element: ElementRef, private telenetIconsRegistry: TelenetIconLibrary, @Optional() @Inject(DOCUMENT) private document: any) { }

   private svgElementFromString(svgContent: string): SVGElement {
      const div = this.document.createElement('DIV');

      div.className = 'telenet-icon';
      div.innerHTML = svgContent;

      return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
   }
}
