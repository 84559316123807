import { OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrors } from '../models/validation-errros.interface';

export function catchValidationErrors<T>(callBackFunc: (errors: ValidationErrors) => void): OperatorFunction<T, T> {
   return catchError((err, caught) => {
      if (err instanceof HttpErrorResponse) {
         const errorBody = {
            errors: err.error.map(x => { return { property: x, error: x } }), //TODO: remove this mapping to add grouping
            hasErrors: true,
            status: err.status
         } as ValidationErrors;

         if (isValidationErrors(errorBody)) {
            callBackFunc(errorBody);
         }
      }

      return throwError(err);
   });
}

export function isValidationErrors(errorBody: any): errorBody is ValidationErrors {
   return errorBody && (errorBody as ValidationErrors).hasErrors;
}
