import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { LoggingService } from '@app/services/logging.service';
import { CookieService } from 'ngx-cookie-service';
import { LanguageOption } from '@app/models/language-option.type';
import { MyCloudCookieConfig } from '@app/models/config/mc-cookie-config.interface';
import { MyCloudOriginSsoConfig } from '@app/models/config/mc-origin-sso-config.interface';
import { MyCloudConfig } from '@app/models/config/mc-config.interface';
import { MyCloudHttpConfig } from '@app/models/config/mc-http-config.interface';
import { MyCloudOktaConfig } from '@app/models/config/mc-okta-config.interface';

@Injectable({
   providedIn: 'root'
})
export class AppSettings {
   private httpClient: HttpClient;
   private config!: MyCloudConfig;

   public hostname: string = "localhost";
   public selectedLanguage = "E";

   constructor(handler: HttpBackend, private log: LoggingService, private cookies: CookieService) {
      this.httpClient = new HttpClient(handler);
   }

   /**
    * Use to get the data found in the second file (config file)
    */
   public getConfig(key: keyof MyCloudConfig): MyCloudConfig[keyof MyCloudConfig] {
      return this.config[key];
   }

   /**
    * Use to get api base url
    */
   public get isInMaintenanceMode(): boolean {
      return this.config?.maintenanceMode ?? false;
   }

   /**
    * Use to bypass Okta Login
    */
   public get bypassOktaLogin(): boolean {
      return this.config?.bypassOktaLogin ?? false;
   }

   /**
    * Use to get api base url
    */
   public get baseApiUrl(): string {
      return this.config?.baseApiUrl;
   }

   /**
    * Use to get api base url
    */
   public get baseCoreApiUrl(): string {
      return this.config?.baseCoreApiUrl;
   }

   /**
    * Use to get core api base url
    */
   public get myProductsUrl(): string {
      return this.config?.myProductsUrl;
   }

   /**
    * Use to get core api base url
    */
   public get cloudMediaUrl(): string {
      return this.config?.cloudMediaUrl;
   }

   /**
    * Use to get http config
    */
   public get httpConfig(): MyCloudHttpConfig {
      return {
         showSuccessGetToast: this.config?.showSuccessGetToast,
         showSuccessNonGetToast: this.config?.showSuccessNonGetToast,
         showValidationErrorToast: this.config?.showValidationErrorToast,
         showPermissionErrorToast: this.config?.showPermissionErrorToast
      } as MyCloudHttpConfig;
   }

   /**
    * Use to get cookies config
    */
   public get cookieConfig(): MyCloudCookieConfig {
      return {
         name: this.config?.cookieName,
         path: this.config?.cookiePath,
         domain: this.config?.cookieDomain
      } as MyCloudCookieConfig;
   }

   /**
    * Use to get origin-sso config
    */
   public get originSsoConfig(): MyCloudOriginSsoConfig {
      return {
         issuer: this.config?.originSsoIssuer,
         authority: this.config?.originSsoAuthority,
         authorizationEndpoint: this.config?.originSsoAuthorizationEndpoint,
         tokenEndpoint: this.config?.originSsoTokenEndpoint,
         endSessionEndpoint: this.config?.originSsoEndSessionEndpoint
      } as MyCloudOriginSsoConfig;
   }

   /**
    * Use to get okta config
    */
   public get oktaConfig(): MyCloudOktaConfig {
      return {
         clientId: this.config?.oktaClientId,
         authority: this.config?.oktaAuthority,
         resetPasswordUrl: this.config?.oktaResetPasswordUrl
      } as MyCloudOktaConfig;
   }

   public originClientId: string = 'mycloudng';
   public get originClientSecret(): string {
      switch (this.hostname) {
         case "localhost":
         case "mycloud.dev.cloud.telenet.be":
         case "mycloud.uat.cloud.telenet.be":
            return "test";
         default:
            return "qCQJyHpr5NwW9WSxmBFaGjTI94vqwAJNAG2Dasnv7JxUUZHrfS";
      }
   }

   public readonly languageCookieName = "HOSTBASKET_LANGUAGE";

   public get languageCookieDomain(): string {
      return window.location.hostname.indexOf(".") !== -1
         ? window.location.hostname.substring(window.location.hostname.indexOf('.'))
         : null;
   }

   public get selectedLanguageLocale(): string {
      switch (this.selectedLanguage) {
         case 'N':
            return 'nl';
         case 'F':
            return 'fr';
         case 'E':
         default:
            return 'en';
      }
   }

   public get myCloudUrl(): string {
      switch (this.hostname) {
         case "localhost":
         case "mycloud.dev.cloud.telenet.be":
            return "http://localhost:4200/";
         case "mycloud.uat.cloud.telenet.be":
         default:
            return `https://${this.hostname}/`;
      }
      }

   /**
    * This method loads "appsettings.json" to get all env's variables
    */
   public init(): Promise<any> {
      this.hostname = window.location.hostname;
      this.log.debug(`Init appsettings for ${this.hostname}`);

      this.selectedLanguage = localStorage.getItem('myCloud.selectedLanguage') ?? 'E';

      return new Promise<boolean>((resolve) => {
         this.httpClient.get<any>('assets/config/appsettings.json', { observe: 'response' }).subscribe((response: HttpResponse<any>) => {
            if (response && response.body) {
               this.config = response.body;
               resolve(true);
            } else {
               this.log.error('appsettings.json file is not valid');
               resolve(true);
            }
         }, (error: any) => {
            this.log.error(error);
            return throwError(error || 'Server error');
         });
      });
   }

   /**
    * This method sets the language of the application and handles the language cookies
    */
   public setLanguage(newLanguage: LanguageOption): void {
      this.log.info("Language set to " + newLanguage);

      //set language
      this.selectedLanguage = newLanguage;
      localStorage.setItem('myCloud.selectedLanguage', this.selectedLanguage);

      //delete and reset cookie
      this.cookies.delete(this.languageCookieName);
      this.cookies.set(this.languageCookieName, newLanguage, { expires: 666, path: this.config?.cookiePath, domain: this.languageCookieDomain });
   }
}
