<div class="maintenance-message">
   <div class="content-container">
      <div class="icon">
         <!-- <telenet-icon name="error"></telenet-icon> -->
      </div>
      <div class="content">
         <div class="content-text">
            <p>
               Om onze cloudoplossingen te optimaliseren, worden enkele belangrijke updates
               op onze systemen voorzien.
            </p>
            <p>
               Tijdens dit onderhoud blijven uw cloudoplossingen volledig operationeel (DNS
               settings, webhosting, Microsoft 365...).
            </p>
            <p>
               Uw mycloud zal dus tijdelijk niet beschikbaar.
            </p>
         </div>
      </div>
   </div>
</div>

<div class="maintenance-message">
   <div class="content-container">
      <div class="icon">
         <!-- <telenet-icon name="error"></telenet-icon> -->
      </div>
      <div class="content">
         <div class="content-text">
            <p>
               Afin d'optimiser nos solutions cloud, quelques mises à jour importantes sont prévues sur nos systèmes.
            </p>
            <p>
               Pendant cette maintenance, vos solutions cloud restent pleinement
               opérationnelles (paramètres DNS, hébergement web, Microsoft 365...).
            </p>
            <p>
               Votre mycloud ne sera donc temporairement pas disponible.
            </p>
         </div>
      </div>
   </div>
</div>

<div class="maintenance-message">
   <div class="content-container">
      <div class="icon">
         <!-- <telenet-icon name="error"></telenet-icon> -->
      </div>
      <div class="content">
         <div class="content-text">
            <p>
               In order to optimize our cloud solutions, some important updates are
               provided on systems.
            </p>
            <p>
               During this maintenance, your cloud solutions remain fully operational (DNS
               settings, web hosting, Microsoft 365...).
            </p>
            <p>
               Your mycloud will therefore be temporarily unavailable.
            </p>
         </div>
      </div>
   </div>
</div>
