import { NgModule, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { AppSettings } from './app.settings';
import { MY_CLOUD_HTTP_CONFIG, MyCloudHttpConfig } from '@app/models/config/mc-http-config.interface';

export function InitializerFactory(appSettings: AppSettings) {
   return () => appSettings.init();
}

export function MyCloudHttpConfigFactory(appSettings: AppSettings): MyCloudHttpConfig {
   return {
      showSuccessGetToast: appSettings.httpConfig.showSuccessGetToast,
      showSuccessNonGetToast: appSettings.httpConfig.showSuccessNonGetToast,
      showValidationErrorToast: appSettings.httpConfig.showValidationErrorToast,
      showPermissionErrorToast: appSettings.httpConfig.showPermissionErrorToast
   };
}

@NgModule({
   providers: []
})
export class AppSettingsModule {
   public static forRoot(): ModuleWithProviders<AppSettingsModule> {
      return {
         ngModule: AppSettingsModule,
         providers: [
            AppSettings,
            {
               provide: APP_INITIALIZER,
               useFactory: InitializerFactory,
               deps: [AppSettings],
               multi: true
            },
            {
               provide: MY_CLOUD_HTTP_CONFIG,
               useFactory: MyCloudHttpConfigFactory,
               deps: [AppSettings],
            }
         ]
      };
   }
}
