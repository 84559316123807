import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { OriginSsoService } from "../services/origin-sso.service";

@Injectable({
   providedIn: "root"
})
export class OriginSsoGuard implements CanActivate {

   constructor(private originSsoService: OriginSsoService) { }

   public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      return this.originSsoService.isAuthenticated;
   }
}
