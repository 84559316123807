import { Injectable } from '@angular/core';
import { TelenetIcon } from './telenet-icons';

@Injectable({
   providedIn: 'root'
})
export class TelenetIconLibrary {
   private registry = new Map<string, string>();

   public registerIcons(icons: TelenetIcon[]): void {
      icons.forEach((icon: TelenetIcon) => this.registry.set(icon.name, icon.data));
   }

   public getIcon(iconName: string): string | undefined {
      if (!this.registry.has(iconName)) {
         console.warn(`We could not find the Icon with the name ${iconName}, did you add it to the Icon library?`);
      }

      return this.registry.get(iconName);
   }
}
