import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "@app/services/authentication.service";
import { Subject, throwError } from "rxjs";
import { takeUntil, catchError } from "rxjs/operators";

@Injectable({
   providedIn: "root"
})
export class CloudErpSsoGuard implements CanActivate, OnDestroy {
   private destroyed: Subject<void> = new Subject<void>();

   constructor(private authService: AuthenticationService, private router: Router) { }

   public ngOnDestroy(): void {
      this.destroyed.next();
      this.destroyed.complete();
   }

   public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const username = route.queryParams["username"];
      const session = route.queryParams["session"];

      if (!username || !session) {
         return false;
      }

      this.authService.loginBySession(username, session).pipe(
         takeUntil(this.destroyed),
         catchError(error => throwError(error))
      ).subscribe(_ => this.router.navigate(['/ng/account/overview']));
   }
}
