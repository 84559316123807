import { Injectable } from '@angular/core';
import { environment } from '@env';

export enum LogLevel {
   All = 0,
   Debug = 1,
   Info = 2,
   Warn = 3,
   Error = 4,
   Fatal = 5,
   Off = 6
}

@Injectable({
   providedIn: 'root'
})
export class LoggingService {

   level: LogLevel = LogLevel.Debug;

   constructor() {
      // Production -> log only warnings and above
      if (environment.production)
         this.level = LogLevel.Warn;
   }

   debug(msg: string, ...optionalParams: any[]) {
      this.writeToLog(msg, LogLevel.Debug, optionalParams);
   }

   info(msg: string, ...optionalParams: any[]) {
      this.writeToLog(msg, LogLevel.Info, optionalParams);
   }

   warn(msg: string, ...optionalParams: any[]) {
      this.writeToLog(msg, LogLevel.Warn, optionalParams);
   }

   error(msg: string, ...optionalParams: any[]) {
      this.writeToLog(msg, LogLevel.Error, optionalParams);
   }

   fatal(msg: string, ...optionalParams: any[]) {
      this.writeToLog(msg, LogLevel.Fatal, optionalParams);
   }

   log(msg: string, ...optionalParams: any[]) {
      this.writeToLog(msg, LogLevel.All, optionalParams);
   }

   private writeToLog(msg: string,
      level: LogLevel,
      params: any[]) {
      if (this.shouldLog(level)) {
         let value: string = "";

         // Build log string
         value = new Date().toLocaleString("nl-be") + " - ";
         value += "(" + LogLevel[level] + ")";
         value += " - " + msg;
         if (params.length) {
            value += " - Info: "
               + this.formatParams(params);
         }
      }
   }

   private shouldLog(level: LogLevel): boolean {
      let ret: boolean = false;

      var usedLevel = this.level;
      // Check if there is a manuel level set by the developper
      var forcedLevel = localStorage.getItem("forceLogLevel") as unknown as LogLevel;
      if (forcedLevel)
         usedLevel = forcedLevel;

      if ((level >= usedLevel &&
         level !== LogLevel.Off) ||
         usedLevel === LogLevel.All) {
         ret = true;
      }
      return ret;
   }

   private formatParams(params: any[]): string {
      let ret: string = params.join(",");
      // Is there at least one object in the array?
      if (params.some(p => typeof p == "object")) {
         ret = "";
         // Build comma-delimited string
         for (let item of params) {
            ret += JSON.stringify(item) + ",";
         }
      }
      return ret;
   }
}
