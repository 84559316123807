import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrorsComponent } from './components/validation-errors.component';
import { ValidationErrorBodyPipe } from './pipes/validation-error-body.pipe';
import { TranslationService } from '@app/services/translation.service';
import { TranslationHandler } from '@app/translations/translation.handler';
import { TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogValidationErrorInterceptor } from './helpers/log-validation-error.interceptor';
import { TelenetIconModule } from '@shared/telenet-icons';

@NgModule({
   declarations: [
      ValidationErrorsComponent,
      ValidationErrorBodyPipe
   ],
   exports: [
      ValidationErrorsComponent,
      ValidationErrorBodyPipe
   ],
   imports: [
      CommonModule,
      TranslateModule.forRoot({
         defaultLanguage: 'E',
         missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: TranslationHandler,
            deps: [TranslationService],
         },
         useDefaultLang: false,
      }),
      TelenetIconModule
   ],
   providers: [
      TranslationService,
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LogValidationErrorInterceptor,
         multi: true
      }
   ]
})
export class ValidationErrorsModule { }
