import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { BruudToastrService } from '@app/services/message.service';
import { CustomerService } from '@app/services/customer.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { OktaOidcService } from '@modules/okta-login/services/okta-oidc.service';

@Component({
   selector: 'my-cloud-login-modal',
   templateUrl: './login.modal.html',
   styleUrls: ['./login.modal.scss']
})
export class LoginModalComponent implements OnDestroy {
   private destroyed: Subject<void> = new Subject<void>();

   public username: string;
   public password: string;

   public errorDescription?: string;

   constructor(public modalRef: NgbActiveModal, public authService: AuthenticationService,
      private oktaOidcService: OktaOidcService, private messageService: BruudToastrService, private customerService: CustomerService) { }

   public ngOnDestroy(): void {
      this.destroyed.next();
      this.destroyed.complete();
   }

   public login(): void {
      this.errorDescription = undefined;

      this.authService.login(this.username, this.password).pipe(
         takeUntil(this.destroyed),
         catchError(error_description => {
            if (error_description) {
               this.errorDescription = error_description;
            }

            return throwError(error_description);
         })
      ).subscribe(_ => {
         this.modalRef.close();
      });
   }

   public resetPassword(): void {
      this.customerService.resetPasswordUnsecure(this.username).pipe(
         takeUntil(this.destroyed)
      ).subscribe(_ => {
         this.messageService.showTranslatedSuccess("BCKOFF_LOGON_PASSWORDWASRESET", null);
      });
   }
}
