import { InjectionToken } from "@angular/core";

export const MY_CLOUD_HTTP_CONFIG = new InjectionToken<MyCloudHttpConfig>('MY_CLOUD_HTTP_CONFIG');

export interface MyCloudHttpConfig {
   showSuccessGetToast: boolean;
   showSuccessNonGetToast: boolean;
   showValidationErrorToast: boolean;
   showPermissionErrorToast: boolean;
}
