export enum CustomerType {
   Endcustomer = 'Endcustomer',
   Partner = 'Partner',
   Varcustomer = 'Varcustomer',
   Rescustomer = 'Rescustomer'
}

export namespace CustomerType {
   export function ToNumber(customerType: CustomerType): number {
      switch (customerType) {
         case CustomerType.Partner:
            return 2;
         case CustomerType.Varcustomer:
            return 3;
         case CustomerType.Rescustomer:
            return 4;
         case CustomerType.Endcustomer:
         default:
            return 1;
      }
   }

   export function FromNumber(customerType: number): CustomerType {
      switch (customerType) {
         case 2:
            return CustomerType.Partner;
         case 3:
            return CustomerType.Varcustomer;
         case 4:
            return CustomerType.Rescustomer;
         case 1:
         default:
            return CustomerType.Endcustomer;
      }
   }
}
