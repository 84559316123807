import { trigger, state, style, transition, animate, keyframes } from "@angular/animations";
import { Component } from "@angular/core";
import { Toast } from "ngx-toastr";
import { triggerChildAnimation } from "./animations/trigger-child.animation";
import { verticalCollapseAnimation } from "./animations/vertical-collapse.animation";

@Component({
   selector: 'my-cloud-custom-toast',
   templateUrl: './custom-toast.component.html',
   styleUrls: ['./custom-toast.component.scss'],
   animations: [
      trigger('slideIn', [
         state('*', style({
            transform: 'translateY(0) scale(1) rotateY(0)',
            opacity: 1,
            filter: 'blur(0) saturate(100%)'
         })),
         state('void', style({
            transform: 'translateY(20px) scale(1.1) rotateY(5deg)',
            opacity: 0,
            filter: 'blur(2px) saturate(50%)'
         })),
         transition('void => *', animate('.3s ease-in-out')),
      ]),
      trigger('slideOut', [
         state('*', style({
            transform: 'translateX(0)  scale(1)',
            opacity: 1,
         })),
         state('void', style({
            transform: 'translateX(100%) scale(.7)',
            opacity: 0,
         })),
         transition('* => void', animate('.2s ease')),
      ]),
      verticalCollapseAnimation,
      triggerChildAnimation
   ],
   preserveWhitespaces: false
})
export class CustomToastComponent extends Toast {

   public get type(): 'success' | 'error' | 'warning' | 'info' {
      return (this.options as any).type;
   }

   public get isSuccessMessage(): boolean {
      return this.type === 'success';
   }

   public get isErrorMessage(): boolean {
      return this.type === 'error';
   }

   public get isWarningMessage(): boolean {
      return this.type === 'warning';
   }

   public get isInfoMessage(): boolean {
      return this.type === 'info';
   }
}
