import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppSettings } from './app.settings';
import { LanguageOption } from '@app/models/language-option.type';
import { OktaService } from '@modules/okta-login/services/okta.service';

@Component({
   selector: 'my-cloud-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
   private destroyed: Subject<void> = new Subject<void>();

   public isOriginPage: boolean = false;
   public isLoading: boolean = false;

   constructor(private router: Router, public appSettings: AppSettings,
      public loader: LoadingBarService, public authService: AuthenticationService, public oktaService: OktaService) { }

   public ngOnInit(): void {
      this.router.events.pipe(
         filter(e => e instanceof NavigationEnd)
      ).subscribe((navEnd: NavigationEnd) => {
         this.isOriginPage = navEnd.urlAfterRedirects.startsWith("/ng/origin/");
      });

      // this.loader.value$.subscribe(percentage => {
      //    this.isLoading = percentage > 0 && percentage < 100;
      // });
   }

   public ngOnDestroy(): void {
      this.destroyed.next();
      this.destroyed.complete();
   }

   public get selectedLanguage(): string {
      switch (this.appSettings.selectedLanguage) {
         case 'N':
            return 'NL';
         case 'F':
            return 'FR';
         case 'E':
         default:
            return 'EN';
      }
   }

   public changeLanguage(language: LanguageOption): void {
      this.authService.changeLanguage(language);
   }

   public logout(): void {
      this.oktaService.logout();
   }
}
