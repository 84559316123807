import { TranslationService } from '@app/services/translation.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class TranslationHandler implements MissingTranslationHandler {
   constructor(private translationsService: TranslationService) { }

   public handle(params: MissingTranslationHandlerParams): Observable<string> {
      return this.translationsService.getTranslation(params.key);
   }
}
