import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { OidcService } from '@app/services/oidc.service';

@Injectable({
   providedIn: 'root'
})
export class OriginOidcService extends OidcService {
   constructor(appSettings: AppSettings) {
      super({
         authority: appSettings.originSsoConfig.authority,
         client_id: appSettings.originClientId,
         client_secret: appSettings.originClientSecret,
         scope: "openid",
         response_type: "code",
         redirect_uri: `${appSettings.myCloudUrl}ng/origin/signin-redirect`,
         post_logout_redirect_uri: `${appSettings.myCloudUrl}ng/origin/signout-redirect`,
         metadata: {
            issuer: appSettings.originSsoConfig.issuer,
            authorization_endpoint: appSettings.originSsoConfig.authorizationEndpoint,
            token_endpoint: appSettings.originSsoConfig.tokenEndpoint,
            end_session_endpoint: appSettings.originSsoConfig.endSessionEndpoint
         },
         loadUserInfo: false
      });
   };
}
