import { Component } from '@angular/core';
import { LoggingService } from '@app/services/logging.service';

@Component({
   selector: 'my-cloud-not-found',
   templateUrl: './not-found.component.html',
   styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
   constructor(log: LoggingService) {
      log.debug("Rendering empty angular component");
   }
}
