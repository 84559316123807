import { NgModule } from '@angular/core';
import { GridUrlSyncDirective } from './directives/grid-url-sync.directive';
import { SearchboxComponent } from './search-box/searchbox.component';
import { FormsModule } from '@angular/forms';

@NgModule({
   declarations: [
      GridUrlSyncDirective,
      SearchboxComponent
   ],
   imports: [
      FormsModule
   ],
   exports: [
      GridUrlSyncDirective,
      SearchboxComponent
   ]
})
export class GridToolsModule { }
