import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AppSettings } from "app/app.settings";

@Injectable({
   providedIn: "root"
})
export class MaintenanceGuard implements CanActivate {

   constructor(private appSettings: AppSettings, private router: Router) { }

   public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (this.appSettings.isInMaintenanceMode && !state.url.includes('/ng/maintenance')) {
         this.router.navigate(['/ng/maintenance']);
         return false;
      }

      if (!this.appSettings.isInMaintenanceMode && state.url.includes('/ng/maintenance')) {
         this.router.navigateByUrl('/ng/account/overview');
      }

      return true;
   }
}
