import { Injectable } from "@angular/core";
import { MessageType } from "@app/models/message-type.enum";
import { TranslationService } from "@app/services/translation.service";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { Observable, of, zip } from "rxjs";
import { map } from "rxjs/operators";


@Injectable({
   providedIn: "root",
})
export class BruudToastrService {

   private config: Partial<IndividualConfig> = {}; //use this later for appsettings.json injection

   constructor(private translationService: TranslationService, private toastr: ToastrService) { }


   public showSuccess(message: string, title?: string): void {
      this.showToast(message, title, MessageType.Success, 5000);
   }

   public showTranslatedSuccess(messageTextFragmentCode: string, titleTextFragmentCode?: string): void {
      this.showTranslatedToast(messageTextFragmentCode, titleTextFragmentCode, MessageType.Success, 5000);
   }


   public showError(text: string, title?: string): void {
      this.showToast(text, title, MessageType.Error, 0);
   }

   public showTranslatedError(messageTextFragmentCode: string, titleTextFragmentCode?: string): void {
      this.showTranslatedToast(messageTextFragmentCode, titleTextFragmentCode, MessageType.Error, 0);
   }


   public showWarning(message: string, title?: string): void {
      this.showToast(message, title, MessageType.Warning, 0);
   }

   public showTranslatedWarning(messageTextFragmentCode: string, titleTextFragmentCode?: string): void {
      this.showTranslatedToast(messageTextFragmentCode, titleTextFragmentCode, MessageType.Warning, 0);
   }


   public showInfo(text: string, title?: string): void {
      this.showToast(text, title, MessageType.Info, 0);
   }

   public showTranslatedInfo(messageTextFragmentCode: string, titleTextFragmentCode?: string): void {
      this.showTranslatedToast(messageTextFragmentCode, titleTextFragmentCode, MessageType.Info, 0);
   }


   private translateTextFragments(messageKey: string, titleKey?: string): Observable<[message: string, title: string]> {
      let messageTranslationObservable = messageKey ? this.translationService.getTranslation(messageKey) : of("");
      let titleTranslationObservable = titleKey ? this.translationService.getTranslation(titleKey) : of("");

      return zip(messageTranslationObservable, titleTranslationObservable).pipe(
         map(([message, title]) => ([message, title]))
      );
   }

   private showTranslatedToast(messageTextFragmentCode: string, titleTextFragmentCode?: string, type: MessageType = MessageType.Info, timeOut: number = 0): void {
      this.translateTextFragments(messageTextFragmentCode, titleTextFragmentCode).subscribe(([message, title]) => {
         this.showToast(message, title, type, timeOut);
      });
   }

   private showToast(message: string, title?: string, type: MessageType = MessageType.Info, timeOut: number = 0): void {
      const config = {
         timeOut: timeOut,
         disableTimeOut: timeOut === 0,
         type: MessageType[type].toString().toLowerCase(),
         ...this.config
      } as Partial<IndividualConfig>;

      switch (type) {
         case MessageType.Success:
            this.toastr.success(message, title, config);
            break;
         case MessageType.Warning:
            this.toastr.warning(message, title, config);
            break;
         case MessageType.Error:
            this.toastr.error(message, title, config);
            break;
         case MessageType.Info:
         default:
            this.toastr.info(message, title, config);
            break;
      }
   }
}
