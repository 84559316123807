import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InlineColumnTemplateDirective } from './directives/inline-column-template.directive';
import { InlineRowTemplateDirective } from './directives/inline-row-template.directive';
import { PageSizeComponent } from './page-size/page-size.component';
import { TranslateModule } from '@ngx-translate/core';
import { GridComponent } from './grid.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TelenetIconModule } from "../telenet-icons/telenet-icon.module";

@NgModule({
   declarations: [
      GridComponent,
      InlineColumnTemplateDirective,
      InlineRowTemplateDirective,
      PageSizeComponent
   ],
   exports: [
      GridComponent,
      InlineColumnTemplateDirective,
      InlineRowTemplateDirective
   ],
   providers: [],
   imports: [
      CommonModule,
      FormsModule,
      TranslateModule,
      FontAwesomeModule,
      TelenetIconModule
   ]
})
export class GridModule { }
