import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppSettings } from "app/app.settings";
import { Profile } from "@app/models/profile.interface";

@Injectable({
   providedIn: 'root'
})
export class ProfileService {
   private readonly baseUri: string;
   private readonly endpoint: string;

   constructor(private httpClient: HttpClient, private appSettings: AppSettings) {
      this.baseUri = `${this.appSettings.baseCoreApiUrl}`;
      this.endpoint = `profile`;
   }

   public getProfile(): Observable<Profile> {
      return this.httpClient.get<Profile>(`${this.baseUri}/${this.endpoint}`);
   }
}
