import { Component } from '@angular/core';
import { AuthenticationService } from '@app/services/authentication.service';
import { CustomerType } from '@app/models/customer-type.enum';
import { Router } from '@angular/router';


@Component({
   selector: 'my-cloud-navigation',
   templateUrl: './navigation.component.html',
   styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

   constructor(private router: Router, public authService: AuthenticationService) { }

   public get customerIsPartner(): boolean {
      return this.authService.customerType == CustomerType.Partner;
   }

   public get activeRoute(): string {
      return this.router.url;
   }
}
