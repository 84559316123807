import { Injectable } from '@angular/core';
import { LoggingService } from '@app/services/logging.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CustomerType } from '@app/models/customer-type.enum';
import { Profile } from '@app/models/profile.interface';
import { CustomerLoginType } from '@app/models/customer-login-type.enum';
import { CookieService } from 'ngx-cookie-service';
import { AppSettings } from 'app/app.settings';
import { LanguageOption } from '@app/models/language-option.type';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from './profile.service';
import { OktaOidcService } from '@modules/okta-login/services/okta-oidc.service';
import { Token } from '@app/models/token.interface';

@Injectable({
   providedIn: 'root'
})
export class AuthenticationService {
   private destroyed: Subject<void> = new Subject<void>();
   private redirectUrl: string;
   private myProfile: Profile;

   constructor(private log: LoggingService, private httpClient: HttpClient, private router: Router, private profileService: ProfileService,
      private cookieService: CookieService, private oktaOidcService: OktaOidcService, private translate: TranslateService, private appSettings: AppSettings) { }

   public ngOnDestroy(): void {
      this.destroyed.next();
      this.destroyed.complete();
   }

   public get token(): Token {
      const localStorageToken = localStorage.getItem('token');
      if (!localStorageToken) {
         return null;
      }

      return <Token>JSON.parse(localStorageToken);
   }

   public get isAuthenticated(): boolean {
      if (!this.token) {
         return false;
      }

      return true;
   }

   public get isOriginSsoAuthenticated(): boolean {
      if (!this.token) {
         return false;
      }

      return this.token.auth_type == "origin";
   }

   public get profile(): Profile {
      return this.myProfile;
   }

   public get userName(): string {
      return this.myProfile?.userName;
   }

   public get userId(): number {
      return +this.myProfile?.userId;
   }

   public get customerName(): string {
      return this.myProfile?.customerName;
   }

   public get customerType(): CustomerType {
      return CustomerType.FromNumber(+this.myProfile?.customerTypeId);
   }

   public get customerLoginType(): CustomerLoginType {
      switch (+this.myProfile?.customerLoginTypeId) {
         case 1:
            return CustomerLoginType.Customer;
         case 2:
            return CustomerLoginType.Alias;
         case 3:
            return CustomerLoginType.SingleSignOnSession;
         case 4:
         default:
            return CustomerLoginType.Anonymous;
      }
   }

   public get hasChannelRights(): boolean {
      return JSON.parse((this.myProfile?.hasChannelRights ?? false).toString());
   }

   public get showCustomerColumn(): boolean {
      return this.customerType === CustomerType.Partner || this.hasChannelRights;
   }

   public storeRedirectUrl(url: string): void {
      this.redirectUrl = url;
   }

   public redirect(): void {
      if (this.redirectUrl) {
         this.log.debug("Redirecting back", this.redirectUrl);

         const url = this.redirectUrl;
         this.redirectUrl = null;

         this.router.navigateByUrl(url);
      }
   }

   public logout(): void {
      this.deleteAuthCookiesAndToken(); //delete all auth cookies
      //QUICK FIX THAT NEED REWORK, don't use the change language here of you get stuck in an endless loop of reload at the maintenance page
      this.setLanguage();
      //and also don't relead here for the same reason
   }

   public changeLanguage(language: LanguageOption): void {
      this.setLanguage(language);

      //reload app (this has to be reworked with language endpoint)
      window.location.reload();
   }

   public login(username: string, password: string): Observable<Profile> {
      this.log.info("Logging in with username and password");

      const uri = `${this.appSettings.baseApiUrl}token`;
      const params = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password`;

      return this.loginCallback(uri, params, username);
   }

   public loginBySession(username: string, session: string): Observable<Profile> {
      this.log.info("Logging in with username and session");

      const uri = `${this.appSettings.baseApiUrl}token`;
      const params = `username=${encodeURIComponent(username)}&session=${encodeURIComponent(session)}&password=&grant_type=password`;

      return this.loginCallback(uri, params, username);
   }

   public getProfile(token: Token): Observable<Profile> {
      if (!token) {
         const unauthenticatedProfile: Profile = {} as Profile;
         return of(unauthenticatedProfile);
      }

      return this.profileService.getProfile().pipe(
         catchError((error) => throwError(error)),
         map(profile => this.myProfile = profile)
      );
   }

   public processToken(token: Token): Observable<Profile> {
      // Replace authentication token
      localStorage.removeItem('token');
      localStorage.setItem("token", JSON.stringify(token));

      return this.getProfile(token);
   }

   public processProfile(profile: Profile): void {
      // SSO to B&B and DDC token lifetime extension when logged in, this will be reworked
      this.cookieService.delete(profile.cookieTokenName);
      this.cookieService.set(profile.cookieTokenName, profile.cookieTokenValue, { expires: 14, domain: profile.cookieDomain, path: '/' });

      // Set customer language
      this.setLanguage(this.profile.cookieLanguageValue);
   }

   // This should be removed when the old authentication system is obsolete
   private loginCallback(uri: string, params: string, username: string): Observable<Profile> {
      return this.httpClient.post<Token>(uri, params).pipe(
         takeUntil(this.destroyed),
         catchError((error: HttpErrorResponse) => {
            if (error.status == 400) {
               this.log.warn("Login failed", username);
            }
            return throwError(error.status == 400 ? error.error.error_description : error);
         }),
         switchMap((token: Token) => this.processToken(token)),
         switchMap((profile: Profile) => {
            this.processProfile(profile);
            return of(profile);
         })
      );
   }

   private setLanguage(language: LanguageOption = 'E'): void {
      this.appSettings.setLanguage(language);
      this.translate.use(this.appSettings.selectedLanguageLocale);
   }

   private deleteAuthCookiesAndToken(): void {
      const cookiesData = this.appSettings.cookieConfig;

      if (this.myProfile?.cookieTokenName) {
         this.cookieService.delete(this.myProfile.cookieTokenName, cookiesData.path, cookiesData.domain);
      }
      this.cookieService.delete(cookiesData.name, cookiesData.path, cookiesData.domain);

      localStorage.removeItem("token");

      this.log.info("Logout cookies and token removed");
   }
}
