import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { CoreModule } from "@app/core.module";
import { SharedModule } from "@shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { MaintenanceGuard } from "./core/guards/maintenance.guard";
import { OriginSsoGuard } from "@modules/origin-sso/guards/origin-sso.guard";
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppSettingsModule } from "./app-settings.module";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { TelenetIconModule } from "@shared/telenet-icons/telenet-icon.module";
import { TelenetIconLibrary } from "@shared/telenet-icons";
import { telenetIconPack } from "@shared/telenet-icons/telenet-icons";
import { CustomToastComponent } from "@app/components/custom-toast/custom-toast.component";

@NgModule({
   declarations: [
      AppComponent
   ],
   imports: [
      AppSettingsModule.forRoot(),
      CoreModule,
      SharedModule,
      AppRoutingModule,
      BrowserModule,
      BrowserAnimationsModule,
      NgbModule,
      LoadingBarHttpClientModule,
      ToastrModule.forRoot({
         timeOut: 10000,
         extendedTimeOut: 15000,
         toastComponent: CustomToastComponent,
         positionClass: "toast-top-full-width",
         iconClasses: { success: '', error: '', warning: '', info: '' },
         closeButton: true,
         autoDismiss: true,
         enableHtml: true,
         preventDuplicates: true,
         countDuplicates: true
      }),
      FontAwesomeModule,
      TelenetIconModule
   ],
   providers: [
      MaintenanceGuard,
      OriginSsoGuard
   ],
   bootstrap: [AppComponent]
})
export class AppModule {
   constructor(faIconLibrary: FaIconLibrary, telenetIconLibrary: TelenetIconLibrary) {
      faIconLibrary.addIconPacks(fas, far, fab);
      telenetIconLibrary.registerIcons(telenetIconPack);
   }
}
