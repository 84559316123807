import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Token } from '@app/models/token.interface';
import { AuthenticationService } from '@app/services/authentication.service';
import { LoggingService } from '@app/services/logging.service';
import { BruudToastrService } from '@app/services/message.service';
import { AppSettings } from 'app/app.settings';
import { Subject, throwError } from 'rxjs';
import { takeUntil, catchError, switchMap, map } from 'rxjs/operators';
import { OktaOidcService } from './okta-oidc.service';

@Injectable({
   providedIn: 'root'
})
export class OktaService implements OnDestroy {
   private destroyed: Subject<void> = new Subject<void>();

   constructor(private oktaOidcService: OktaOidcService, private authService: AuthenticationService, private log: LoggingService, private messageService: BruudToastrService,
      private router: Router, private httpClient: HttpClient, private appSettings: AppSettings) { }

   public ngOnDestroy(): void {
      this.destroyed.next();
      this.destroyed.complete();
   }

   public onOktaLoginCompleted(): void {
      this.Login()
   }

   private Login(): void {
      this.log.info("Logging in with Okta");

      this.oktaOidcService.getAccessToken().then(ssoToken => {
         const headers = new HttpHeaders({
            'Authorization': `bearer ${ssoToken}`,
            'Content-Type': 'application/json'
         });
         
         this.httpClient.get<Token>(`${this.appSettings.baseCoreApiUrl}/okta/token`, { headers: headers }).pipe(
            takeUntil(this.destroyed),
            catchError(err => {
               if (err instanceof HttpErrorResponse) {
                  if (err.status == 401) {
                     this.messageService.showTranslatedError('BCKOFF_OKTA_ALREADYLOGGEDIN', 'Error');
                  }
                  if (err.status == 404) {
                     this.messageService.showTranslatedError('BCKOFF_OKTA_USERNOTFOUND', 'Error');
                  }
                  return throwError(err);
               }
            }),
            switchMap(token => this.authService.processToken(token)),
            map(profile => this.authService.processProfile(profile)),
          ).subscribe(_ => {
            this.log.info("Okta login complete");
            location.href = '';
         });
      });
   }

   public logout(): void {
      this.oktaOidcService.isLoggedIn().then(loggedIn => {
         if (loggedIn) {
            this.oktaOidcService.logout();
         }
         else{
            this.authService.logout();
            location.href = '';
         }
      });
   }
}
