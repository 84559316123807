import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service'
import { TokenInterceptor } from '@app/interceptors/token.interceptor';
import { LoggingService } from './services/logging.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from './services/translation.service';
import { LoginModalComponent } from './components/login-modal/login.modal';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { TranslationHandler } from './translations/translation.handler';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SharedModule } from '@shared/shared.module';
import { ReponseToastInterceptor } from './interceptors/response-toast.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { CloudErpSsoComponent } from './components/cloud-erp-sso.component';
import { TelenetIconModule } from '@shared/telenet-icons';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';

@NgModule({
   declarations: [
      LoginModalComponent,
      NavigationComponent,
      MaintenanceComponent,
      ModalLoginComponent,
      NotFoundComponent,
      CloudErpSsoComponent,
      CustomToastComponent
   ],
   exports: [
      LoginModalComponent,
      NavigationComponent
   ],
   entryComponents: [
      LoginModalComponent
   ],
   imports: [
      CommonModule,
      FormsModule,
      RouterModule,
      TranslateModule.forRoot({
         defaultLanguage: 'E',
         missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: TranslationHandler,
            deps: [TranslationService],
         },
         useDefaultLang: false,
      }),
      HttpClientModule,
      NgbModule,
      SharedModule,
      TelenetIconModule
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: TokenInterceptor,
         multi: true
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: ReponseToastInterceptor,
         multi: true
      },
      CookieService,
      AuthGuard
   ]
})
export class CoreModule {
   constructor(private log: LoggingService) {
      this.log.info("Loading core module");
   }
}
