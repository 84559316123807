<div class="modal-body">
   <form (ngSubmit)="login()">
      <div class="form-group">
         <label for="username" [innerHTML]="'USERNAME' | translate"></label>
         <input type="text" class="form-control form-control-sm" autocomplete="username" id="username" name="username" [(ngModel)]="username" />
      </div>
      <div class="form-group mt-2">
         <label for="password" [innerHTML]="'PASSWORD' | translate"></label>
         <my-cloud-password-input [(value)]="password"></my-cloud-password-input>
      </div>

      <div class="login-error" *ngIf="errorDescription">
         <ngb-alert class="mt-2" type="danger" [dismissible]="false" [innerHTML]="errorDescription"></ngb-alert>
      </div>

      <div class="actions d-flex justify-content-between">
         <a class="reset-password" (click)="resetPassword()">
            <span> {{'BCKOFF_LOGON_FORGOT_PASSWORD' | translate}} </span>
         </a>
         <button (click)="login()" class="btn btn-telenet">{{'LOGIN' | translate}}</button>
      </div>
   </form>
</div>