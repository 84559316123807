<div class="custom-toast" [class]="type" @slideIn @slideOut>
   <div class="content-container">
      <div class="icon">
         <telenet-icon [class]="type" name="success" *ngIf="isSuccessMessage"></telenet-icon>
         <telenet-icon [class]="type" name="error" *ngIf="isErrorMessage"></telenet-icon>
         <telenet-icon [class]="type" name="alert" *ngIf="isWarningMessage"></telenet-icon>
         <telenet-icon [class]="type" name="information" *ngIf="isInfoMessage"></telenet-icon>
      </div>
      <div class="content">
         <div class="content-title" *ngIf="title">{{title}}</div>
         <div class="content-message" [innerHTML]="message"></div>
      </div>
   </div>
   <div class="dismiss" *ngIf="options.closeButton" (click)="remove()">
      <telenet-icon name="close"></telenet-icon>
   </div>
</div>