import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { CustomerAdminModel } from "../../modules/account/models/customer-admin.model";
import { CustomerInvoiceModel } from "../../modules/account/models/customer-invoice.model";
import { MediaTypeModel } from "../../modules/account/models/media-type.model";
import { VatTypeModel } from "../../modules/account/models/vat-type.model";
import { CustomerContactModel } from "../../modules/account/models/customer-contact.model";
import { CustomerSearch } from "../../modules/account/models/customer.search";
import { PagedResultSet } from "@shared/grid-tools";
import { ExportCustomersQuery } from "../../modules/account/models/customer-export.command";
import { CustomerGridModel } from "../../modules/account/models/customer-grid.model";
import { AddCustomerCommand } from "../../modules/account/models/add-customer.command";
import { AppSettings } from "app/app.settings";
import { noResponseToastHeaders } from "@shared/constants";
import { CustomerAnnouncementModel } from "@modules/account/models/customer-announcement.model";

@Injectable({
   providedIn: 'root'
})
export class CustomerService {
   private readonly baseUri: string;
   private readonly endpoint: string;

   constructor(private httpClient: HttpClient, private appSettings: AppSettings) {
      this.baseUri = `${this.appSettings.baseCoreApiUrl}`;
      this.endpoint = `customer`;
   }

   public searchCustomers(page: number, pageSize: number, sortBy: string, sortDirection: any, customerSearch: CustomerSearch): Observable<PagedResultSet<CustomerGridModel>> {
      let params = new HttpParams()
         .append('Page', page.toString())
         .append('PageSize', pageSize.toString())
         .append('SortBy', sortBy);

      if (sortDirection) {
         params = params.append('SortDirection', sortDirection);
      }

      if (customerSearch?.search) {
         params = params.append('Filters.Search', customerSearch.search);
      }

      return this.httpClient.get<PagedResultSet<CustomerGridModel>>(`${this.baseUri}/${this.endpoint}`, { params });
   }

   public exportCustomers(query: ExportCustomersQuery): Observable<Blob> {
      const httpOptions = {
         responseType: 'blob' as 'json'
      };

      return this.httpClient.post<Blob>(`${this.baseUri}/${this.endpoint}/export-csv`, query, httpOptions);
   }

   public addCustomer(command: AddCustomerCommand): Observable<number> {
      return this.httpClient.post<number>(`${this.baseUri}/${this.endpoint}/add`, command);
   }

   public getVatTypes(): Observable<Array<VatTypeModel>> {
      return this.httpClient.get<Array<VatTypeModel>>(`${this.baseUri}/${this.endpoint}/vat-types`, { headers: noResponseToastHeaders });
   }

   public getMediaTypes(customerId: number): Observable<Array<MediaTypeModel>> {
      return this.httpClient.get<Array<MediaTypeModel>>(`${this.baseUri}/${this.endpoint}/media-types?customerId=${customerId}`, { headers: noResponseToastHeaders });
   }

   public getAdminInfo(customerId: number): Observable<CustomerAdminModel> {
      return this.httpClient.get<CustomerAdminModel>(`${this.baseUri}/${this.endpoint}/admin?customerId=${customerId}`);
   }

   public updateAdminInfo(customerAdminModel: CustomerAdminModel): Observable<CustomerAdminModel> {
      return this.httpClient.put<CustomerAdminModel>(`${this.baseUri}/${this.endpoint}/admin`, customerAdminModel);
   }

   public getInvoiceInfo(customerId: number): Observable<CustomerInvoiceModel> {
      return this.httpClient.get<CustomerInvoiceModel>(`${this.baseUri}/${this.endpoint}/invoice?customerId=${customerId}`);
   }

   public updateInvoiceInfo(customerInvoiceModel: CustomerInvoiceModel): Observable<CustomerInvoiceModel> {
      return this.httpClient.put<CustomerInvoiceModel>(`${this.baseUri}/${this.endpoint}/invoice`, customerInvoiceModel);
   }

   public getVarCustomerCanLogin(customerId: number): Observable<boolean> {
      return this.httpClient.get<boolean>(`${this.baseUri}/${this.endpoint}/var-customer-can-login?customerId=${customerId}`);
   }

   public resetPasswordUnsecure(username: string): Observable<void> {
      return this.httpClient.put<any>(`${this.appSettings.baseApiUrl}/Account/ResetCustomerPasswordUnsecure`, { username });
   }

   public getContacts(): Observable<Array<CustomerContactModel>> {
      return this.httpClient.get<Array<CustomerContactModel>>(`${this.baseUri}/${this.endpoint}/contacts`);
   }

   public createContact(contactModel: CustomerContactModel): Observable<CustomerContactModel> {
      return this.httpClient.post<CustomerContactModel>(`${this.baseUri}/${this.endpoint}/contact`, contactModel);
   }

   public updateContact(contactModel: CustomerContactModel): Observable<CustomerContactModel> {
      return this.httpClient.put<CustomerContactModel>(`${this.baseUri}/${this.endpoint}/contact/${contactModel.id}`, contactModel);
   }

   public deleteContact(contactId: number): Observable<number> {
      return this.httpClient.delete<number>(`${this.baseUri}/${this.endpoint}/contact/${contactId}`);
   }

   public unlockContact(contactId: number): Observable<void> {
      return this.httpClient.put<void>(`${this.baseUri}/${this.endpoint}/contact/${contactId}/unlock`, { contactId });
   }

   public getAllUnreadAnnouncements(): Observable<Array<CustomerAnnouncementModel>> {
      return this.httpClient.get<Array<CustomerAnnouncementModel>>(`${this.baseUri}/${this.endpoint}/announcement/unread/all`);
   }

   public announcementIsRead(announcementId: number): Observable<boolean> {
      return this.httpClient.put<boolean>(`${this.baseUri}/${this.endpoint}/announcement/${announcementId}/read`, {}, { headers: noResponseToastHeaders });
   }
}
