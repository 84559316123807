import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { OidcService } from '@app/services/oidc.service';

@Injectable({
   providedIn: 'root'
})
export class OktaOidcService extends OidcService {
   constructor(appSettings: AppSettings) {
      super({
         client_id: appSettings.oktaConfig.clientId,
         authority: appSettings.oktaConfig.authority,       
         scope: "openid profile email",
         response_type: "code",  
         redirect_uri: `${appSettings.myCloudUrl}ng/okta/signin-redirect`,
         post_logout_redirect_uri: `${appSettings.myCloudUrl}ng/okta/signout-redirect`,
         loadUserInfo: false
      });
   };
}
