import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HighlightSearchDirective } from './helpers/highlight-search.directive';
import { AutcompleteLabelTemplateDirective, AutcompleteOptionTemplateDirective, AutocompleteFooterTemplateDirective, AutocompleteHeaderTemplateDirective } from './helpers/autocomplete-templates.directive';
import { InternalLabelPipe } from './helpers/internal-label.pipe';
import { SearchAutocompleteComponent } from './component/search-autocomplete.component';

@NgModule({
   declarations: [
      SearchAutocompleteComponent,
      HighlightSearchDirective,
      AutcompleteOptionTemplateDirective,
      AutcompleteLabelTemplateDirective,
      AutocompleteHeaderTemplateDirective,
      AutocompleteFooterTemplateDirective,
      InternalLabelPipe
   ],
   exports: [
      SearchAutocompleteComponent,
      HighlightSearchDirective,
      AutcompleteOptionTemplateDirective,
      AutcompleteLabelTemplateDirective,
      AutocompleteHeaderTemplateDirective,
      AutocompleteFooterTemplateDirective,
      InternalLabelPipe
   ],
   imports: [
      FormsModule,
      CommonModule,
      NgSelectModule
   ]
})
export class SearchAutocompleteModule { }
