import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelenetIconComponent } from './telenet-icon.component';

@NgModule({
   imports: [CommonModule],
   declarations: [TelenetIconComponent],
   exports: [TelenetIconComponent]
})
export class TelenetIconModule { }
