import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { LoginModalComponent } from "@app/components/login-modal/login.modal";
import { Token } from "@app/models/token.interface";
import { AuthenticationService } from '@app/services/authentication.service';
import { LoggingService } from '@app/services/logging.service';
import { OktaOidcService } from "@modules/okta-login/services/okta-oidc.service";
import { OriginOidcService } from "@modules/origin-sso/services/origin-oidc.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppSettings } from "app/app.settings";
import { Subject } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivateChild, OnDestroy {
   private destroyed: Subject<void> = new Subject<void>();

   constructor(public log: LoggingService, private appSettings: AppSettings,
      private authService: AuthenticationService, private oktaOidcService: OktaOidcService, private originOidcService: OriginOidcService,
      private modalService: NgbModal, private router: Router) { }

   public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      // All login methods
      if (!this.authService.isAuthenticated) {
         this.log.info("User not authenticated, redirecting to login");

         if (this.appSettings.bypassOktaLogin){
            this.router.navigate(['/modal-login']);
            return false;
         }

         this.oktaOidcService.login();
         return false;
      }

      // Session
      if (Number(this.authService.token.expires_at)*1000 < Date.now()) {
         this.log.info("Token expired, logout and redirecting to login");

         this.authService.logout();
         location.href = '';
                  
         return false;
      }

      // Origin login is only allowed to access domain pages
      if (this.authService.isOriginSsoAuthenticated && !state.url.startsWith("/ng/domain")) {
         return false;
      }

      return true;
   }

   public ngOnDestroy(): void {
      this.destroyed.next();
      this.destroyed.complete();
   }

   private openLoginModal(): void {
      const modalRef = this.modalService.open(LoginModalComponent, {
         ariaLabelledBy: "modal-basic-title",
         backdrop: 'static',
         keyboard: false,
         modalDialogClass: 'login-modal',
         scrollable: true
      });

      this.destroyed.subscribe(_ => modalRef.dismiss());

      modalRef.result.then(_ => this.authService.redirect(), _ => void 0);
   }
}
