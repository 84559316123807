import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/services/authentication.service';
import { LoggingService } from '@app/services/logging.service';
import { AppSettings } from 'app/app.settings';
import { Token } from '@app/models/token.interface';


/**
 * Token interceptor -> add authentication token if available
 **/
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
   private requests: HttpRequest<any>[] = [];

   constructor(private authService: AuthenticationService, private log: LoggingService, private appSettings: AppSettings) { }

   public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let changedRequest = request;

      const headerSettings: { [name: string]: string | string[] } = {};
      for (const key of request.headers.keys().filter(k => k != "noloader")) {
         headerSettings[key] = request.headers.getAll(key);
      }

      headerSettings['Language'] = this.appSettings.selectedLanguage;

      let token: Token = this.authService.token;
      if (token && token.access_token) {
         this.log.debug("Adding token to HTTP request");
         headerSettings['Authorization'] = 'bearer ' + token.access_token;
         headerSettings['Content-Type'] = 'application/json';
      }

      const newHeader = new HttpHeaders(headerSettings);

      changedRequest = request.clone({
         headers: newHeader
      });

      this.requests.push(changedRequest);

      return new Observable(observer => {
         const subscription = next.handle(changedRequest).subscribe(event => {
            if (event instanceof HttpResponse) {
               this.removeRequest(changedRequest);
               observer.next(event);
            }
         }, err => {
            this.removeRequest(changedRequest);
            observer.error(err);
         }, () => {
            this.removeRequest(changedRequest);
            observer.complete();
         });

         // remove request from queue when cancelled
         return () => {
            this.removeRequest(changedRequest);
            subscription.unsubscribe();
         };
      });
   }

   public removeRequest(req: HttpRequest<any>): void {
      const i = this.requests.indexOf(req);
      if (i >= 0) {
         this.requests.splice(i, 1);
      }
   }
}
