import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolverService implements Resolve<any> {

  constructor(private authService: AuthenticationService) { }

  resolve(): Observable<any> {
    if (this.authService.isAuthenticated && !this.authService.userId)
    {
        return this.authService.getProfile(this.authService.token);
    }
  }
}
