import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "@app/services/authentication.service";

@Component({
   selector: "my-cloud-maintenance",
   templateUrl: "./maintenance.component.html",
   styleUrls: ["./maintenance.component.scss"],
})
export class MaintenanceComponent implements OnInit {
   constructor(private authenticationService: AuthenticationService) { }

   public ngOnInit(): void {
      this.authenticationService.logout();
   }
}
