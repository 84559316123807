import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginModalComponent } from '@app/components/login-modal/login.modal';
import { AuthenticationService } from '@app/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
   selector: 'modal-login',
   template: `<div></div>`
})
export class ModalLoginComponent implements OnInit {

   constructor(private router: Router, private authService: AuthenticationService, private modalService: NgbModal) { }

   public ngOnInit(): void {
      //TODO change this in this.authService.isAuthenticated when okta environment is present
         this.openLoginModal();
   }

   private openLoginModal(): void {
      const modalRef = this.modalService.open(LoginModalComponent, {
         ariaLabelledBy: "modal-basic-title",
         backdrop: 'static',
         keyboard: false,
         modalDialogClass: 'login-modal',
         scrollable: true
      });

      modalRef.result.then(_ => {
         modalRef.dismiss();
         location.href = '';
      }
      );
   }
}
