

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
   selector: "my-cloud-password-input",
   templateUrl: "./password-input.component.html",
   styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent {
   public isVisible: boolean = false;
   public id: string = 'password';

   @Input() public value: string;
   @Input() public autocomplete: string = "password";

   @Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();
}
