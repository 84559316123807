import { UserManager, User, SignoutResponse, UserManagerSettings } from 'oidc-client';
import { Subject } from 'rxjs';
import { OidcContext } from '@shared/models/oidc-context';

export class OidcService {
   private userManager: UserManager;
   private user: User;
   private loginChangedSubject = new Subject<boolean>();

   public loginChanged = this.loginChangedSubject.asObservable();
   public authContext: OidcContext;

   constructor(private settings: UserManagerSettings) {

      this.userManager = new UserManager(this.settings);
      this.userManager.events.addAccessTokenExpired(_ => {
         this.loginChangedSubject.next(false);
      });

      this.userManager.events.addUserLoaded(user => {
         if (this.user !== user) {
            this.user = user;
            this.loginChangedSubject.next(!!user && !user.expired);
         }
      });
   }

   public login(): Promise<void> {
      return this.userManager.signinRedirect();
   }

   public isLoggedIn(): Promise<boolean> {
      return this.userManager.getUser().then(user => {
         const userCurrent = !!user && !user.expired;
         if (this.user !== user) {
            this.loginChangedSubject.next(userCurrent);
         }
         this.user = user;

         return userCurrent;
      });
   }

   public completeLogin(): Promise<User> {
      return this.userManager.signinRedirectCallback().then(user => {
         this.user = user;
         this.loginChangedSubject.next(!!user && !user.expired);
         return user;
      });
   }

   public logout(): Promise<void> {
      return this.userManager.signoutRedirect();
   }

   public completeLogout(): Promise<SignoutResponse> {
      this.user = null;
      this.loginChangedSubject.next(false);

      return this.userManager.signoutRedirectCallback();
   }

   public getAccessToken(): Promise<string> {
      return this.userManager.getUser().then(user => !!user && !user.expired ? user.access_token : null);
   }
}
